//import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";
// import bg6 from '../../assets/bg-6.avif'

import cld1 from '../../assets/cloud/bg-2.webp'
// import cld3 from '../../assets/cloud/bg-3.webp'
import cld2 from '../../assets/cloud/CLOUD-F.jpg'

const CloudServices = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return(
        <motion.main
        key="about"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:.8}}
        >
            <Nav/>
                <div className="w-11/12 mt-16 md:mt-28 md:w-4/6 mx-auto">
                    <h1 className="text-2xl md:text-5xl hd text-center">Cloud Services</h1>
                    <p className="text-center my-4 md:my-6 w-full md:w-10/12 mx-auto">
                    Harness the power of the cloud to drive agility and scalability in your operations. Our expert team assists you in formulating a cloud strategy, seamlessly migrating your infrastructure, and managing cloud environments. Benefit from cost-effective resources, enhanced flexibility, and accelerated business growth with our comprehensive cloud solutions.
                    </p>
                </div>

                <img className="w-screen mt-8 md:mt-12 object-cover h-[40vh] md:h-[60vh]" src={cld2} alt='' />
                
                <div className="w-full md:w-4/6 my-5  flex md:rounded-xl flex-col-reverse md:flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" alt="" src={cld1} />
                    <div className="w-full md:w-1/2 flex  justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-16 gap-4 anim text-tqdarkblue">
                            <div className="flex flex-col gap-8 md:justify-start">
                                <h3 className="text-3xl font-semibold">Cloud Platform Services (AWS, Azure, GCP)</h3>
                                <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">Build your Cloud Journey with TeqInifity Cloud Platform services. Our Cloud Platform Services help  enhances your enterprise Cloud experience by leveraging below services: </p>
                                <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                    <li>Cloud Infrastructure Services</li>
                                    <li>Hybrid and Multi-cloud deployment</li>
                                    <li>Cloud Transition & Transformation</li>
                                    <li>Application Modernization</li>
                                    <li>Cloud Maturity Assessment</li>
                                    <li>Cloud DevOps</li>
                                    <li>Cloud FinOps</li>
                                    <li>Cloud Operating model</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            <Footer/>
        </motion.main>
    )
}

export default CloudServices;