import React, { Component } from "react";
import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";

import p1 from '../assets/partners/p0.png'
import p2 from '../assets/partners/p1.png'
import p3 from '../assets/partners/p2.png'
import p4 from '../assets/partners/p3.png'
import p5 from '../assets/partners/p4.png'
import p6 from '../assets/partners/p5.png'
import p7 from '../assets/partners/p6.png'
import p9 from '../assets/partners/p7.png'
import p10 from '../assets/partners/p8.png'
import p11 from '../assets/partners/p10.png'
import p12 from '../assets/partners/p11.png'
import p13 from '../assets/partners/p12.png'
import p8 from '../assets/partners/p13.png'
import p15 from '../assets/partners/p14.png'
import p14 from '../assets/partners/p9.png'

export default class Autoplay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 2,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 100,
      cssEase: "linear"
    };
    return (
      <div className="w-full md:w-1/2 mx-auto my-12 mb-24 md:my-24">
        <Slider {...settings}>
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p1} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p2} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p3} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p4} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p5} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p6} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p7} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p8} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p9} />
          <img className="px-3 object-contain h-16 w-16" src={p10} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p11} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p12} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p13} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p14} />
          <img className="px-4 md:px-8 object-contain h-16 w-16" src={p15} />
        </Slider>
      </div>
    );
  }
}