//import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";
import cc from '../../assets/consult/consult1.webp'
import cc1 from '../../assets/consult/consult-F.webp'

const ConsultingServices = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return(
        <motion.main
        key="about"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:.8}}
        >
            <Nav/>
            <div className="w-11/12 mt-16 md:mt-28 md:w-4/6 mx-auto">
                <h1 className="text-2xl md:text-5xl hd text-center">Consulting Services</h1>
                <p className="text-center my-4 md:my-6 w-full md:w-10/12 mx-auto">
                Benefit from our strategic IT consulting services, where we assess your current IT landscape, identify opportunities for improvement, and provide recommendations to align your technology investments with your business goals. Our expert consultants guide you in making informed decisions and implementing effective IT strategies.                </p>
            </div>

            <div className="overflow-hidden my-12 relative h-[40vh] md:h-[60vh]">
                <img className="w-screen absolute -top-24 mt-36 md:mt-12 object-cover" src={cc1} alt='' />
            </div>

            <div className="w-full md:w-4/6 my-5 p-6 md:p-8 flex md:rounded-xl md:flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                <div className="">
                    <h3 className="text-3xl font-semibold">Consulting Services</h3>
                    <p className="mx-auto md:mx-0 font-light mt-4 opacity-80">Benefit from our strategic IT consulting services, where we assess your current IT landscape, identify opportunities for improvement, and provide recommendations to align your technology investments with your business goals. Our expert consultants guide you in making informed decisions and implementing effective IT strategies.</p>
                    <ul className="mt-8 list-inside">
                        <ll>
                            <h3 className="text-2xl list-item my-3 mt-12 font-semibold">Network Services:</h3>
                            <ul className="mt-6">
                                <li className="text-xl mt-6 my-4 font-semibold text-tqdarkblue/70">Network Design and Architecture:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Network Assesement and Audit</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Network Optimization and Performance Tuning:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Network Security Consulting:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Network Design and Architecture:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Network Documentation and Documentation Management: </li>
                            </ul>
                        </ll>

                        <ll>
                            <h3 className="text-2xl list-item my-3 mt-12 font-semibold">Datacenter Services:</h3>
                            <ul className="mt-6">
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Datacenter Planning and Design:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Datacenter Migration and Consolidation:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Datacenter Virtualization:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Datacenter Security and Compliance:</li>
                            </ul>
                        </ll>

                        <ll>
                            <h3 className="text-2xl list-item my-3 mt-12 font-semibold">Cloud Services:</h3>
                            <ul className="mt-6">
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Cloud Strategy and Roadmap:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Cloud Migration and Deployment:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Cloud Optimization and Management:</li>
                                <li className="text-xl list-item mt-6 my-4 font-semibold text-tqdarkblue/70">Multi-Cloud and Hybrid Cloud Solutions:</li>
                            </ul>
                        </ll>

                    </ul>
                </div>
            </div>
{/*             
            <div className="w-full md:w-4/6 my-5  flex md:rounded-xl flex-col-reverse md:flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={cc} />
                <div className="w-full md:w-1/2 flex  justify-start">
                    <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-16 gap-4 anim text-tqdarkblue">
                        <div className="flex flex-col gap-8 md:justify-start">
                            <h3 className="text-3xl font-semibold">Consulting Services</h3>
                            <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">Build your Cloud Journey with TeqInifity Cloud Platform services. Our Cloud Platform Services help  enhances your enterprise Cloud experience by leveraging below services: </p>
                            <ul className="leading-10 list-none md: text-left mx-auto w-fit block ml-4 md:ml-8">
                                <li>Enterprise Server Infrastructure management - Windows, Linux, Unix</li>
                                <li>Virtualization - Vmware, Hyper-V</li>
                                <li>Backup & Recovery</li>
                                <li>Database Services - SQL, Oracle</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}

            <Footer/>
        </motion.main>
    )
}

export default ConsultingServices;