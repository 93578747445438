//import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";
import eu from '../../assets/enduser/enduser1.webp'
import eu2 from '../../assets/enduser/enduser-.webp'

const EndUserServices = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return(
        <motion.main
        key="about"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:.8}}
        >
            <Nav/>
            <div className="w-11/12 mt-16 md:mt-28 md:w-4/6 mx-auto">
                <h1 className="text-2xl md:text-5xl hd text-center">End User Services</h1>
                <p className="text-center my-4 md:my-6 w-full md:w-10/12 mx-auto">
                Empower your workforce with reliable and efficient IT support. Our end user services cover help desk support, software installations and upgrades, device management, and user training. We prioritize user satisfaction, ensuring your employees have the tools and assistance they need to thrive.
                </p>
            </div>

            <img className="w-screen mt-8 md:mt-12 object-cover h-[40vh] md:h-[60vh]" src={eu} alt='' />
            
            <div className="w-full md:w-4/6 my-5  flex md:rounded-xl flex-col-reverse md:flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={eu2} />
                <div className="w-full md:w-1/2 flex  justify-start">
                    <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-16 gap-4 anim text-tqdarkblue">
                        <div className="flex flex-col gap-8 md:justify-start">
                            <h3 className="text-3xl font-semibold">End User Services</h3>
                            <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">Empower your workforce with reliable and efficient IT support. Our end user services cover help desk support, software installations and upgrades, device management, and user training. We prioritize user satisfaction, ensuring your employees have the tools and assistance they need to thrive.</p>
                            <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                <li>Virtual Desktop Infrastructure</li>
                                <li>Desktop and Mobile device management</li>
                                <li>Directory Services - Active Directory, ADFS, Azure AD</li>
                                <li>Messaging and Collaboration</li>
                                <li>Desktop Infrastructure Automation - SCCM</li>
                                <li>Endpoint Security - Antivirus & Malware protection</li>   
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </motion.main>
    )
}

export default EndUserServices;