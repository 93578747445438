import { Link } from "react-router-dom";
import logo from "../assets/zqx-full-w.png";

import { AiFillInstagram } from "react-icons/ai";
import { IoLogoFacebook, IoLogoLinkedin, IoLogoTwitter, IoLogoYoutube } from "react-icons/io5";
import { IoLogoWhatsapp, IoMdCall } from "react-icons/io";
import { HiOfficeBuilding, HiLink } from "react-icons/hi";
import { RiAppsFill } from "react-icons/ri";

const Footer = () => {
    return (
        <div className="bg-tqdarkblue mt-8 md:min-h-[50vh]">
            <div className="flex scale-100 md:scale-90 pt-12 gap-16 px-12 overflow-hidden text-white flex-col md:flex-row md:justify-between py-6 w-full">
                <div className="flex flex-col  items-center scale-90 md:scale-100">
                    <img
                        className="h-8 md:h-10 object-contain"
                        src={logo}
                        alt=""
                    />
                    <div className="flex gap-4 mt-10">
                        <a href="https://www.instagram.com/zenquix_technologies" target="_blank">
                            <AiFillInstagram size="30px" />
                        </a>
                        <a href="https://wa.me/919663026085"target="_blank">
                            <IoLogoWhatsapp size="30px" />
                        </a>
                        <a href="https://www.youtube.com/@zenquixtechnologies" target="_blank">
                            <IoLogoYoutube size="30px" />
                        </a>
                        <a href="https://www.linkedin.com/company/zenquix" target="_blank">
                            <IoLogoLinkedin size="30px" />
                        </a>
                    </div>
                </div>
                <div className="">
                    <h2 className="text-xl flex gap-2 justify-center md:justify-start items-center font-medium text-center md:text-right mb-6">
                        Useful Links
                        <HiLink />
                    </h2>
                    <div className="flex flex-col mt-8 mx-auto text-white/70 items-center md:items-start gap-4">
                        <Link
                            to="/"
                            className="uppercase text-sm hover:text-white anim anim"
                        >
                            Home
                        </Link>
                        <Link
                            to="/about-us"
                            className="uppercase text-sm hover:text-white anim anim"
                        >
                            About Us
                        </Link>
                        {/* <Link to='/services' className="uppercase text-sm  anim">Services</Link> */}
                        <Link
                            to="/contact"
                            className="uppercase text-sm hover:text-white anim anim"
                        >
                            Contact
                        </Link>
                    </div>
                </div>
                <div className="sm:block md:hidden text-center md:text-left lg:block">
                    <h2 className="text-xl flex gap-2 justify-center md:justify-start items-center font-medium text-center md:text-right mb-6">
                        Services
                        <RiAppsFill />
                    </h2>
                    <div className="flex flex-col mx-auto text-white/70 items-center md:items-start gap-4">
                        <Link
                            className="hover:text-white anim"
                            to="/network-services"
                        >
                            Network Services
                        </Link>
                        <Link
                            className="hover:text-white anim"
                            to="/cloud-services"
                        >
                            Cloud Services
                        </Link>
                        <Link
                            className="hover:text-white anim"
                            to="/datacenter-services"
                        >
                            Datacenter Services
                        </Link>
                        <Link
                            className="hover:text-white anim"
                            to="/end-user-services"
                        >
                            End User Services
                        </Link>
                        <Link
                            to="/software-services"
                            className="hover:text-white anim"
                        >
                            Software Services
                        </Link>
                        <Link
                            to="/consulting-services"
                            className="hover:text-white anim"
                        >
                            Consulting Services
                        </Link>
                        <Link
                            to="/it-staff-augmentation"
                            className="hover:text-white anim"
                        >
                            IT Staff Augmentation
                        </Link>
                    </div>
                </div>
                <div className="md:w-3/6 lg:w-1/4">
                    <h2 className="text-xl flex gap-2 justify-center md:justify-end items-center font-medium text-center md:text-right mb-6">
                        <HiOfficeBuilding /> Address
                    </h2>
                    <div className="flex items-center md:items-end text-white/70 flex-col gap-6">
                        <p className="uppercase text-sm text-center md:text-right anim">
                            Corporate Office : G1, XII XIII XIV PART, VGN Krona,
                            Madha Town Extention, Gerugambakam, Chennai –
                            600122, Tamilnadu
                        </p>
                        <p className="uppercase  text-center md:text-right text-sm  anim">
                            Branch office : 1383/433, 4th Floor, 5 Block HBR Layout, Service Road, Outer Ring Road, Bangalore-560045 , Karnataka
                        </p>
                    </div>
                    <h2 className="text-xl flex gap-2 mt-8 justify-center md:justify-end items-center font-medium text-center md:text-right mb-6">
                        <IoMdCall />
                        Contact
                    </h2> 
                    <div className="flex w-full justify-center text-white/70 md:justify-end items-center md:items-end mt-4 gap-4">
                        <a
                            href="tel:919663026085"
                            className="uppercase text-center md:text-right anim"
                        >
                            +91 9663026085
                        </a>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default Footer;
