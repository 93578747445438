
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import nwbg from '../assets/network/bg-3.webp'

import { useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import {VscCompassActive, VscGift} from 'react-icons/vsc'
import icon from '../assets/Icon-w.svg'

import {BiBullseye} from 'react-icons/bi'
import {BsEyeFill} from 'react-icons/bs'
import {IoDiamond} from 'react-icons/io5'


const About = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return(
        <motion.main
        key="about"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:.6}}
        >
            <Nav className='topppp'/><div className="md:my-12 block">

            </div>
                <div className="mb-12">
                    <img className="w-full brightness-50 h-[50vh] botttt object-cover" alt='' src={nwbg} />
                    <motion.div
                    initial={{opacity:0, y:40}}
                    whileInView={{opacity:'100', y:0}}
                    transition={{duration:.6}}
                    className="w-full mt-16 pointer-events-none absolute top-0 botttt h-[40vh] flex flex-col justify-center items-center">
                        <h1 className="hd text-white font-medium text-3xl md:text-5xl topppp">About ZenQuix Technologies inc    </h1>
                        <p className="text-white font-light text-center w-10/12 mt-3 opacity-75">We provide world class Networking, Infrastructure, Computing & so many other services</p>
                    </motion.div> 
                </div>
            <div className="w-10/12 md:w-4/6 py-6 block mx-auto">
                <h2 className="text-2xl md:text-3xl hd">Who we are?</h2>
                <p className="text-black/70 text-justify mt-4 mb-10 md:mt-6">
                At Zenquix, our inspiration comes from the wisdom of Zen philosophy where tranquility, clarity, precision, and finesse combined with boundless energy and agility pave the path to enlightenment. In today’s VUCA world, where change is inevitable and innovation is constant, ZenQuix embraces adaptability, agility, and efficiency with elegance to meet the evolving needs of the market and clients. Our solutions are crafted with the help of our guiding principles of simplicity, mindfulness, and focusing on what truly matters – delivering exceptional outcomes that are quick and creating a meaningful impact. With Zenquix as your trusted partner, let’s embark on a transformative journey of growth and success.
                </p>
                <Link className="bg-tqdarkblue hover:bg-tqblue md:scale-90 hover:scale-95 anim pointer-events-auto text-white px-12 py-3 md:py-4 text-sm md:text-base" to='/about-us'>View Services</Link>
            </div>

            <div className="w-10/12 md:w-4/6 py-8 mt-2 block mx-auto">
                <h2 className="text-2xl md:text-3xl hd">What we do?</h2>
                <p className="text-black/70 text-justify mt-4 md:mt-6">
                At ZenQuix Technologies, we offer a comprehensive range of IT services to empower businesses with seamless technology solutions. Our expert team specializes in the following areas: Network Services, Cloud Services, Datacenter Services, Software Development, End User Services, IT Staff Augmentaion & Consulting Services</p>
            </div>

            <div className="w-10/12 md:w-4/6 py-8 mt-2 block mx-auto">
                <h2 className="text-2xl md:text-3xl hd">Why ZenQuix Technologies?</h2>
                <p className="text-black/70 text-justify mt-4 md:mt-6">
                Choose ZenQuix Technologies as your trusted IT partner. Experience our expertise, comprehensive solutions, tailored approach, innovation-driven mindset, customer-centricity, and unwavering reliability. We are here to transform your technology landscape, optimize your IT operations, and empower your organization for success in the digital age.
                </p>
            </div>

            <div className="flex flex-col flex-wrap md:flex-row mx-auto w-11/12 md:w-4/6 mt-2  gap-8">
                <div className="flex gap-8 flex-wrap md:flex-nowrap">
                    <div className="p-6 w-full md:w-1/2 overflow-hidden md:p-10 bg-tqdarkblue hover:bg-tqblue anim group text-white shadow-xl relative rounded-2xl py-8 block mx-auto">
                        <h1 className="text-2xl hd">Our Mission</h1>
                        <BiBullseye className='absolute scale-150 group-hover:opacity-40 anim -top-12 -right-12 opacity-10' size='150px' />
                        <p className="text-white/70 text-base mt-4">
                        At ZenQuix Technologies, our mission is to go beyond being a standard IT services provider. We are passionate about transforming businesses through technology, driving innovation, and delivering exceptional results.
                        </p>
                    </div>
                    <div className="p-6 w-full md:w-1/2 overflow-hidden md:p-10 bg-tqdarkblue hover:bg-tqblue anim group text-white shadow-xl relative rounded-2xl py-8 block mx-auto">
                        <h1 className="text-2xl hd">Our Vision</h1>
                        <BsEyeFill className='absolute scale-150 group-hover:opacity-40 anim -top-12 -right-12 opacity-10' size='150px' />
                        <p className="text-white/70 text-base mt-4">
                        ZenQuix Technologies's vision is to lead the way as a premier provider of comprehensive IT services, renowned for our innovative solutions, expertise, and unwavering commitment to our clients' success. We aim to drive positive transformations in businesses by harnessing the full potential of technology.
                        </p>
                    </div>
                </div>
                <div className="p-6 w-full overflow-hidden md:p-10 bg-tqdarkblue hover:bg-tqblue anim group text-white shadow-xl relative rounded-2xl py-8 block mx-auto">
                    <h1 className="text-2xl hd">Core Values</h1>
                    <IoDiamond className='absolute scale-150 group-hover:opacity-40 anim -top-2 -right-12 opacity-10' size='150px' />
                    <p className="text-white/70 text- text-base mt-4">
                    At ZenQuix Technologies, our core values are the guiding principles that define our company. We are driven by a commitment to excellence, always striving to deliver the highest quality IT services. Integrity is at the forefront of our interactions, ensuring honesty, transparency, and trust in all relationships. Our customer focus drives us to listen attentively, understand needs deeply, and provide personalized solutions. Innovation is in our DNA, as we embrace emerging technologies and creative thinking to empower our clients. Collaboration fuels our success, enabling us to leverage diverse expertise and work together seamlessly. We take accountability for our actions, meeting commitments and taking ownership of outcomes. Continuous improvement is ingrained in our culture, as we embrace learning, feedback, and growth. These core values form the foundation of ZenQuix Technologies and guide us as we support our clients in achieving their goals.
                    </p>
                </div>
            </div>
            
            
            <div className="w-10/12 md:w-4/6 mt-10 md:mt-12 mb-24 mx-auto">
                {/* <div className="mt-8 flex flex-wrap gap-12 justify-center">
                    <div className="flex flex-col justify-center text-center items-center gap-4 w-[20rem] h-[20rem] bg-tqdarkblue hover:scale-105 anim rounded-3xl text-white">
                        <img className="w-[50px]" alt='' src={icon} />
                        <h3 className="text-2xl font-semibold">Who we are?</h3>
                        <p className="w-10/12 opacity-80">In today's fast-moving markets, companies no longer just want a vendor who responds to requests at a minimal cost </p>
                    </div>
                    <div className="flex flex-col justify-center text-center items-center gap-4 w-[20rem] h-[20rem] bg-tqdarkblue hover:scale-105 anim rounded-3xl text-white">
                        <VscCompassActive size='50px'/>
                        <h3 className="text-2xl font-semibold">What do we provide?</h3>
                        <p className="w-10/12 opacity-80">In today's fast-moving markets, companies no longer just want a vendor who responds to requests at a minimal cost </p>
                    </div>
                    <div className="flex flex-col justify-center text-center items-center gap-4 w-[20rem] h-[20rem] bg-tqdarkblue hover:scale-105 anim rounded-3xl text-white">
                        <VscGift size='50px'/>
                        <h3 className="text-2xl font-semibold">Why Us?</h3>
                        <p className="w-10/12 opacity-80">In today's fast-moving markets, companies no longer just want a vendor who responds to requests at a minimal cost </p>
                    </div>
                </div> */}
                <div className="flex pc flex-col mt-20 gap-2">
                    <h3 className="text-3xl hd font-semibold mb-8">Some of our expert Services</h3>
                    <div className="flex gap-4 flex-wrap items-center w-full">
                        <Link to='/network-services' className="py-2 text-black/70 hover:text-tqblue anim hover:underline">Network Services</Link>/
                        <Link to='/cloud-services' className="py-2 text-black/70 hover:text-tqblue anim hover:underline">Cloud Services</Link>/
                        <Link to='/datacenter-services' className="py-2 text-black/70 hover:text-tqblue anim hover:underline">Datacenter Services</Link>/
                        <Link to='/end-user-services' className="py-2 text-black/70 hover:text-tqblue anim hover:underline">End User Services</Link>/
                        <Link to='/software-services' className="py-2 text-black/70 hover:text-tqblue anim hover:underline">Software Services</Link>/
                        <Link to='/consulting-services' className="py-2 text-black/70 hover:text-tqblue anim hover:underline">Consulting Services</Link>/
                        <Link to='/it-staff-augmentation' className="py-2 text-black/70 hover:text-tqblue anim hover:underline">IT Staff Augmentation</Link>
                    </div>
                </div>
            </div>


            <Footer/>
        </motion.main>
    )
}

export default About;