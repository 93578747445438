// import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import { useEffect } from "react";
import { motion } from "framer-motion";

import contact from "../assets/contact-f.webp";

import { AiFillInstagram } from "react-icons/ai";
import { IoLogoFacebook, IoLogoTwitter } from "react-icons/io5";
import { HiOfficeBuilding } from "react-icons/hi";
import { IoLogoWhatsapp, IoMdCall } from "react-icons/io";

const Contact = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
    const currentYear = new Date().getFullYear();

    return (
        <motion.main
            key="about"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
        >
            <Nav />
            <div className="relative min-h-[70vh] md:py-12 overflow-hidden">
                <img
                    className="w-screen h-screen absolute brightness-50 botttt z-[-999999999] object-cover "
                    alt=""
                    src={contact}
                />
                <div className="w-11/12 md:w-4/6 mt-24 text-white toppppp mx-auto">
                    <h1 className="hd text-5xl  text-center">Contact</h1>
                    <p className="block text-center mt-6">
                        We welcome the opportunity to connect with you and
                        discuss how ZenQuix Technologies can help transform your
                        IT infrastructure. Please reach out to us using any of
                        the following contact methods
                    </p>
                    <a
                        href="/"
                        className="text-tqdarkblue mx-auto hover:scale-105 mt-8 anim pointer-events-auto bg-white px-12 py-3 md:py-4 block w-fit  text-sm md:text-base"
                    >
                        Book an Appointment
                    </a>
                </div>
            </div>
            <div className="flex justify-center w-full text-tqdarkblue gap-4 mt-24">
                <a href="https://">
                    <AiFillInstagram size="60px" />
                </a>
                <a href="https://">
                    <IoLogoWhatsapp size="60px" />
                </a>
                <a href="https://">
                    <IoLogoFacebook size="60px" />
                </a>
                <a href="https://">
                    <IoLogoTwitter size="60px" />
                </a>
            </div>
            <div className="mt-8">
                <div className="flex flex-col gap-2 text-center justify-center w-11/12 mx-auto">
                    <h2 className="text-xl flex gap-2 justify-center items-center font-medium text-center  my-6">
                        <HiOfficeBuilding /> Corporate office{" "}
                    </h2>
                    <a
                        href="/"
                        className="uppercase text-sm block mx-auto anim"
                    >
                        G1, XII XIII XIV PART, VGN Krona, Madha Town Extention,
                        Gerugambakam, Chennai - 600122, Tamilnadu
                    </a>
                    <h2 className="text-xl flex gap-2 justify-center items-center font-medium text-center  my-6">
                        <HiOfficeBuilding /> Branch office{" "}
                    </h2>
                    <a
                        href="/"
                        className="uppercase text-sm block mx-auto anim"
                    >
                        DSR Green Vista, ECC Road, Whitefield, Bangalore -
                        560066, Karnataka
                    </a>
                </div>
                <h2 className="text-xl flex gap-2 mt-8 justify-center  items-center font-medium text-center md:text-right mb-6">
                    <IoMdCall />
                    Contact
                </h2>
                <div className="flex w-full justify-center text-tqdarkblue/70 items-center md:items-end mt-4 gap-4">
                    <a
                        href="tel:919663026085"
                        className="uppercase text-center anim"
                    >
                        +91 9663026085
                    </a>
                </div>
            </div>
            <p className="text-xs text-center my-12">
                Copyrights Recieved &copy; {currentYear}
            </p>
        </motion.main>
    );
};

export default Contact;
