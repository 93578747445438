//import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";

import nw from '../../assets/itstaff/itstaff-1.webp'
import nw2 from '../../assets/itstaff/itstaff-2.webp'

const DatacenterServices = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return(
        <motion.main
        key="about"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:.8}}
        >
            <Nav/>
            <div className="w-11/12 mt-16 md:mt-28 md:w-4/6 mx-auto">
                <h1 className="text-2xl md:text-5xl hd text-center">IT Staff Augmentation Services</h1>
                <p className="text-center my-4 md:my-6 w-full md:w-10/12 mx-auto">
                Fill critical skill gaps in your organization with our IT staff augmentation services. We provide highly skilled and experienced professionals to supplement your existing team and ensure smooth project execution. Whether you need temporary or long-term resources, we can provide the right talent to support your initiatives.                </p>
            </div>

            <img className="w-screen mt-8 md:mt-12 object-cover h-[40vh] md:h-[60vh]" src={nw2} alt='' />
            
            <div className="w-full md:w-4/6 my-5 mt-16 flex md:rounded-xl flex-col-reverse md:flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={nw} />
                <div className="w-full md:w-1/2 flex  justify-start">
                    <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-16 gap-4 anim text-tqdarkblue">
                        <div className="flex flex-col gap-8 md:justify-start">
                            <h3 className="text-3xl font-semibold">IT Staff Augmentatiion Services</h3>
                            <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">Fill critical skill gaps in your organization with our IT staff augmentation services. We provide highly skilled and experienced professionals to supplement your existing team and ensure smooth project execution. Whether you need temporary or long-term resources, we can provide the right talent to support your initiatives.</p>
                            <h3 className="text-xl font-semibold">We have experts of various IT professionals for your specific project needs.</h3>
                            <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                <li>Network Implementation and Support</li>
                                <li>Datacenter Implementation and Support</li>
                                <li>Cloud Network Support & Cloud DevOps</li>
                                <li>Application Support</li>
                                <li>End User Support</li>
                                <li>Onsite Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </motion.main>
    )
}

export default DatacenterServices;