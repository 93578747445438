//import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";

import nw from '../../assets/network/slide1.webp'
import nw2 from '../../assets/network/bg-3.webp'
import nw5 from '../../assets/network/bg-2.webp'


import ns1 from '../../assets/network/enterp-nw.webp'
import ns2 from '../../assets/network/datacenter-nw.webp'
import ns3 from '../../assets/network/nw-security.webp'
import ns4 from '../../assets/network/sdn.webp'
import ns5 from '../../assets/network/nw-supp.webp'

const NetworkServices = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return(
        <motion.main
        key="about"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:.8}}
        >
            <Nav/>
            <div className=" mt-16 md:mt-28 mx-auto">
                <div className="w-11/12 md:w-4/6 mx-auto">
                    <h1 className="text-2xl md:text-5xl hd text-center">Networking Services</h1>
                    <p className="text-center my-4 md:my-6 w-full md:w-10/12 mx-auto">
                    We provide robust and secure network solutions tailored to your organization's requirements. From designing and implementing networks to optimizing performance and ensuring network security, our experts ensure seamless connectivity and reliable communication.
                    </p>
                </div>

                <img className="w-screen mt-8 md:mt-12 object-cover h-[40vh] md:h-[60vh]" src={nw} alt='' />

                <div className="w-full lg:w-4/6 my-5 flex md:rounded-xl flex-wrap md:flex-nowrap justify-between md:min-h-[30vh] mt-12 bg-tqdarkblue md:mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={ns1} />
                    <div className="w-full md:w-1/2 flex rounded-xl justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-10 md:my-16 gap-4 anim text-white">
                            <div className="flex flex-col gap-8 md:justify-start">
                                <h3 className="text-3xl font-semibold">Enterprise Networking</h3>
                                <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80 ">
                                Revolutionize your business connectivity with our cutting-edge enterprise networking solutions. We design, deploy, and manage robust network infrastructures that enable seamless communication, collaboration, and data transfer within your organization. Our expertise ensures high-performance networks that meet your evolving needs, empowering you to drive productivity and achieve your business goals.
                                </p>
                                <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                    <li>LAN</li>
                                    <li>WAN</li>
                                    <li>SD-WAN</li>
                                    <li>Wireless</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-4/6 my-5 flex md:rounded-xl flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={ns2} />
                    <div className="w-full md:w-1/2 flex  justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-10 md:my-16 gap-4 anim text-tqdarkblue">
                            <div className="flex flex-col gap-8 md:justify-start">
                                <h3 className="text-3xl font-semibold">Datacenter Networking</h3>
                                <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">
                                Trust us with your datacenter networking requirements and experience unparalleled reliability and efficiency. Our datacenter networking solutions encompass advanced architectures, virtualization technologies, and optimized storage and compute systems. With our expertise, you can ensure secure and scalable connectivity for your critical applications and data, enabling seamless operations and resource utilization.
                                </p>
                                <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                    <li>VXLAN EVPN-based Datacenter Fabric (Cisco Nexus, Arista, Juniper)</li>
                                    <li>Cisco ACI Datacenter Fabric</li>
                                    <li>Cumulus based VXLAN EVPN Fabric (Whitebox solution)</li>
                                    <li>SDN - Cisco ACI, NFV - NSX-V and NSX-T</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-4/6 my-5 flex flex-row rounded-xl flex-wrap md:flex-nowrap justify-between bg-tqdarkblue md:min-h-[30vh] md:mx-auto">
                    <img className="w-full md:w-1/2 md:rounded-xl min-h-[50vh] object-cover" src={ns4} />
                    <div className="w-full md:w-1/2 flex  justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-10 md:my-16 gap-4 anim text-white">
                            <div className="flex flex-col gap-8 md:justify-start">
                                <h3 className="text-3xl font-semibold">Software Defined Networking </h3>
                                <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">
                                Embrace the agility and flexibility of software-defined networking to streamline your network infrastructure. Our SDN solutions centralize network control, automate provisioning, and optimize traffic flow, allowing you to adapt rapidly to changing demands. Experience enhanced network management, improved scalability, and simplified operations with our SDN expertise.
                                </p>
                                <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                    <li>SDN - Cisco ACI, VXLAN EVPN (Cisco Nexus & Arista)</li>
                                    <li>NFV - NSX-V and NSX-T</li>
                                    <li>SDWAN</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-4/6 my-5 flex flex-row-reverse md:rounded-xl flex-wrap md:flex-nowrap justify-between md:min-h-[30vh] bg-tqdarkblue/5 md:mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={ns3} />
                    <div className="w-full md:w-1/2 flex  justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-10 md:my-16 gap-4 anim text-tqdarkblue">
                            <div className="flex flex-col gap-8 md:justify-start">
                                <h3 className="text-3xl font-semibold">Network Security</h3>
                                <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">
                                Protect your network from cyber threats and safeguard your valuable data with our comprehensive network security solutions. We employ industry-leading practices to implement robust security measures, including firewalls, intrusion detection and prevention systems, encryption, and secure remote access, ensuring the confidentiality, integrity, and availability of your network resources.
                                </p>
                                <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                    <li>Next-Gen Firewall</li>
                                    <li>Load Balancer</li>
                                    <li>Proxy</li>
                                    <li>Web Application Firewall (WAF)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-4/6 my-5 flex md:rounded-xl flex-wrap lg:flex-nowrap justify-between md:min-h-[30vh] bg-tqdarkblue mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={ns5} />
                    <div className="w-full md:w-1/2 flex justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-8 md:flex-nowrap w-full mx-auto items-start my-10 md:my-16 gap-4 anim text-white">
                            <div className="flex flex-col gap-8 md:justify-start">
                                <h3 className="text-3xl font-semibold">Network Support Services</h3>
                                <p className=" mx-auto md:mx-0 font-light -mt-4 opacity-80">
                                Our dedicated network support services ensure your network operates at peak performance and remains secure and reliable. We provide proactive monitoring, troubleshooting, maintenance, and optimization services to detect and resolve network issues promptly, minimizing downtime and maximizing network efficiency.                                </p>
                                <ul className="leading-10 list-none md:list-disc text-left mx-auto w-fit block ml-4 md:ml-8">
                                    <li>Remote network monitoring</li>
                                    <li>Network connectivity solutions</li>
                                    <li>Scheduled system and security updates</li>
                                    <li>OS upgrades</li>
                                    <li>Network troubleshooting</li>
                                    <li>Application patches</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </motion.main>
    )
}

export default NetworkServices;