import Nav from "../components/Nav";
import Footer from "../components/Footer";
import PartnerCarousel from "../components/Partners";
import Autoplay from "../components/HeadCarousel";

// import bg1 from '../assets/bg-1.webp'
// import bg2 from '../assets/bg-2.webp'

import logo from '../assets/zqx-full-clr.png'
import bg6 from '../assets/network/bg-3.webp'
import bg8 from '../assets/network/slide1.webp'
import dc from '../assets/datacenter/srvr (1).webp'
import nw from '../assets/network/slide1.webp'
import cld1 from '../assets/cloud/bg-2.webp'
import eu from '../assets/enduser/enduser1.webp'
import sw from '../assets/software/sw2.webp'
import icon from '../assets/zqx2.png'
import wwr from '../assets/Who we are.webp'
import wt from '../assets/Why TeqInfinity-1.webp'

import { Link } from "react-router-dom";
// import icon1 from '../assets/Icon.svg'
// import chain from '../assets/chain.svg'

import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";

import {VscCompassActive, VscGift} from 'react-icons/vsc'
import Carousel from "../components/Carousel";

const Home = () => {
    
    // const item = {
    //     hidden: { opacity: 0, y: 12 },
    //     show: { opacity: 1, y: 0 },
    //     viewport:{ once: true },
    // };
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return(
        <AnimatePresence>
            <motion.main
            key="hom"
            initial={{opacity:0}}
            animate={{opacity:1}}
            transition={{duration:1}}
            >
                <Nav className='toppppp'/><div className="md:mt-12"></div>
                
                <Autoplay/>

                <div className="w-full lg:w-4/6 my-5 p-6 md:p-8 mt-2 flex md:rounded-xl flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover md:rounded-xl" src={wwr} />
                    <div className="w-full md:w-1/2 flex rounded-xl justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-4 md:px-8 md:flex-nowrap w-full mx-auto items-start mt-6 gap-4 anim text-tqdarkblue">
                            <div className="flex flex-col text-center md:text-left gap-8 md:justify-start">
                                <img className="h-[30px] object-contain mx-auto md:ml-0 md:mr-auto" alt='' src={logo} />
                                <h3 className="text-2xl md:text-3xl -mt-2 font-semibold">Who we are?</h3>
                                <p className=" mx-auto md:mx-0 text-justify md:text-left -mt-4 opacity-80">At Zenquix, our inspiration comes from the wisdom of Zen philosophy where tranquility, clarity, precision, and finesse combined with boundless energy and agility pave the path to enlightenment. In today’s VUCA world, where change is inevitable and innovation is constant, ZenQuix embraces adaptability, agility, and efficiency with elegance to meet the evolving needs of the market and clients. Our solutions are crafted with the help of our guiding principles of simplicity, mindfulness, and focusing on what truly matters – delivering exceptional outcomes that are quick and creating a meaningful impact. With Zenquix as your trusted partner, let’s embark on a transformative journey of growth and success.</p>
                                <Link className="text-tqblue" to='/about-us'>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-4/6 my-5 p-6 md:p-8 flex md:rounded-xl flex-row flex-wrap md:flex-nowrap justify-between bg-tqdarkblue md:min-h-[30vh] md:mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover rounded-xl mt-4 md:mt-0" src={bg8} />
                    <div className="w-full md:w-1/2 flex rounded-xl justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-4 md:px-8 md:flex-nowrap w-full mx-auto items-start mt-6 gap-4 anim text-white">
                            <div className="flex flex-col text-center md:text-left gap-8 md:pl-4 md:justify-start">
                                <VscCompassActive className="mx-auto md:mx-0" size='50px'/>
                                <h3 className="text-2xl md:text-3xl -mt-2 font-semibold">What we do?</h3>
                                <p className=" mx-auto md:mx-0 text-justify md:text-left -mt-4 opacity-80">At ZenQuix Technologies, we offer a comprehensive range of IT services to empower businesses with seamless technology solutions. Our expert team specializes in the following areas: Network Services, Cloud Services, Datacenter Services, Software Development, End User Services, IT Staff Augmentaion & Consulting Services</p>
                                <Link to='/about-us'>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-4/6 my-5 p-6 md:p-8 flex md:rounded-xl flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                    <img className="w-full md:w-1/2 min-h-[40vh] object-cover rounded-xl  mt-4 md:mt-0" src={wt} />
                    <div className="w-full md:w-1/2 flex rounded-xl justify-start">
                        <div className="flex flex-row-reverse flex-wrap px-4 md:px-8  md:flex-nowrap w-full mx-auto items-start mt-6 gap-4 anim text-tqdarkblue">
                            <div className="flex flex-col text-center md:text-left gap-8 md:justify-start">
                                <VscGift className=" mx-auto md:mx-0" size='50px'/>
                                <h3 className="text-2xl md:text-3xl -mt-2 font-semibold">Why ZenQuix Technologies?</h3>
                                <p className=" mx-auto md:mx-0 text-justify md:text-left -mt-4 opacity-80">Choose ZenQuix Technologies as your trusted IT partner. Experience our expertise, comprehensive solutions, tailored approach, innovation-driven mindset, customer-centricity, and unwavering reliability. We are here to transform your technology landscape, optimize your IT operations, and empower your organization for success in the digital age.</p>
                                <Link className="text-tqblue" to='/about-us'>Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="text-2xl mt-24 text-center md:text-3xl font-semibold hd">Our Partners</h3>

                <PartnerCarousel/>             

                <Footer/>
            </motion.main>
        </AnimatePresence>
    )
}

export default Home;