import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./slick.css";
import "./slick-theme.css";

import bg6 from '../assets/network/bg-3.webp';
import dc from '../assets/datacenter/srvr (1).webp';
import nw from '../assets/network/slide1.webp';
import cld1 from '../assets/cloud/bg-2.webp';
import eu from '../assets/enduser/enduser1.webp';
import sw from '../assets/software/sw2.webp';
import logo from "../assets/zqx-full-w.png";

export default class Autoplay extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
        };
        return (
            <div className="w-full md:my-12 md:mb-24">
                <Slider {...settings}>
                    {/* First Slide */}
                    <div className="w-full relative">
                        <img className="w-full brightness-[.4] h-[50vh] md:h-[60vh] object-cover" alt="" src={bg6} />
                        <motion.div
                            initial={{ opacity: 0.1, y: 40 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            className="w-full h-[50vh] md:h-[60vh] absolute top-0 flex flex-col justify-center items-center"
                        >
                            <h1 className="text-white text-center font-medium text-2xl md:text-5xl mb-4 md:mb-8 flex flex-col md:flex-row">
                                <span className="flex items-center justify-center whitespace-nowrap">
                                    Welcome to
                                    <img
                                        className="mx-2 inline-block"
                                        src={logo}
                                        alt="Logo"
                                        style={{ height: '1em' }} // Matches the text height
                                    />
                                </span>
                                <span className="block md:inline-flex">
                                    Technologies Pvt Ltd
                                </span>
                            </h1>
                            <p className="text-white font-light text-center w-11/12 md:w-10/12 opacity-75 mb-4">
                                A leading provider of comprehensive IT services tailored to meet the diverse needs of businesses....
                            </p>
                            <Link
                                className="bg-white hover:scale-95 anim pointer-events-auto text-tqdarkblue px-8 py-2 md:px-12 md:py-4 text-xs md:text-base"
                                to="/about-us"
                            >
                                Learn More
                            </Link>
                        </motion.div>

                    </div>

                    {/* Second Slide */}
                    <div className="w-full relative">
                        <img className="w-full brightness-[.5] h-[50vh] md:h-[60vh] object-cover" alt="" src={nw} />
                        <motion.div
                            initial={{ opacity: 0.1, y: 40 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            className="w-full h-[50vh] md:h-[60vh] absolute top-0 flex flex-col justify-center items-center"
                        >
                            <h1 className="text-white text-center font-medium text-2xl md:text-5xl mb-4 md:mb-8">
                                Network Services
                            </h1>
                            <p className="text-white font-light text-center w-11/12 md:w-10/12 opacity-75 mb-4">
                                Trust us with your datacenter networking requirements and experience unparalleled reliability and efficiency....
                            </p>
                            <Link
                                className="bg-white hover:scale-95 anim pointer-events-auto text-tqdarkblue px-8 py-2 md:px-12 md:py-4 text-xs md:text-base"
                                to="/network-services"
                            >
                                Learn More
                            </Link>
                        </motion.div>
                    </div>

                    {/* Third Slide */}
                    <div className="w-full relative">
                        <img className="w-full brightness-[.5] h-[50vh] md:h-[60vh] object-cover" alt="" src={cld1} />
                        <motion.div
                            initial={{ opacity: 0.1, y: 40 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            className="w-full h-[50vh] md:h-[60vh] absolute top-0 flex flex-col justify-center items-center"
                        >
                            <h1 className="text-white text-center font-medium text-2xl md:text-5xl mb-4 md:mb-8">
                                Cloud Services
                            </h1>
                            <p className="text-white font-light text-center w-11/12 md:w-10/12 opacity-75 mb-4">
                                Harness the power of the cloud to drive agility and scalability in your operations.
                            </p>
                            <Link
                                className="bg-white hover:scale-95 anim pointer-events-auto text-tqdarkblue px-8 py-2 md:px-12 md:py-4 text-xs md:text-base"
                                to="/cloud-services"
                            >
                                Learn More
                            </Link>
                        </motion.div>
                    </div>

                    {/* Fourth Slide */}
                    <div className="w-full relative">
                        <img className="w-full brightness-[.5] h-[50vh] md:h-[60vh] object-cover" alt="" src={dc} />
                        <motion.div
                            initial={{ opacity: 0.1, y: 40 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            className="w-full h-[50vh] md:h-[60vh] absolute top-0 flex flex-col justify-center items-center"
                        >
                            <h1 className="text-white text-center font-medium text-2xl md:text-5xl mb-4 md:mb-8">
                                Data Center Services
                            </h1>
                            <p className="text-white font-light text-center w-11/12 md:w-10/12 opacity-75 mb-4">
                                Entrust your critical infrastructure to our experienced team for comprehensive datacenter management.
                            </p>
                            <Link
                                className="bg-white hover:scale-95 anim pointer-events-auto text-tqdarkblue px-8 py-2 md:px-12 md:py-4 text-xs md:text-base"
                                to="/datacenter-services"
                            >
                                Learn More
                            </Link>
                        </motion.div>
                    </div>

                    {/* Fifth Slide */}
                    <div className="w-full relative">
                        <img className="w-full brightness-[.5] h-[50vh] md:h-[60vh] object-cover" alt="" src={eu} />
                        <motion.div
                            initial={{ opacity: 0.1, y: 40 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            className="w-full h-[50vh] md:h-[60vh] absolute top-0 flex flex-col justify-center items-center"
                        >
                            <h1 className="text-white text-center font-medium text-2xl md:text-5xl mb-4 md:mb-8">
                                End User Services
                            </h1>
                            <p className="text-white font-light text-center w-11/12 md:w-10/12 opacity-75 mb-4">
                                Empower your workforce with reliable and efficient IT support.
                            </p>
                            <Link
                                className="bg-white hover:scale-95 anim pointer-events-auto text-tqdarkblue px-8 py-2 md:px-12 md:py-4 text-xs md:text-base"
                                to="/end-user-services"
                            >
                                Learn More
                            </Link>
                        </motion.div>
                    </div>

                    {/* Sixth Slide */}
                    <div className="w-full relative">
                        <img className="w-full brightness-[.5] h-[50vh] md:h-[60vh] object-cover" alt="" src={sw} />
                        <motion.div
                            initial={{ opacity: 0.1, y: 40 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1 }}
                            className="w-full h-[50vh] md:h-[60vh] absolute top-0 flex flex-col justify-center items-center"
                        >
                            <h1 className="text-white text-center font-medium text-2xl md:text-5xl mb-4 md:mb-8">
                                Software Development Services
                            </h1>
                            <p className="text-white font-light text-center w-11/12 md:w-10/12 opacity-75 mb-4">
                                Transform your business with tailor-made software solutions that meet your specific requirements.
                            </p>
                            <Link
                                className="bg-white hover:scale-95 anim pointer-events-auto text-tqdarkblue px-8 py-2 md:px-12 md:py-4 text-xs md:text-base"
                                to="/software-services"
                            >
                                Learn More
                            </Link>
                        </motion.div>
                    </div>
                </Slider>
            </div>
        );
    }
}
