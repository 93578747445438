//import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";

// import sw from '../../assets/software/sw1.webp'
import sw2 from '../../assets/software/sw2.webp'

const DatacenterServices = () => {
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    return(
        <motion.main
        key="about"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:.8}}
        >
            <Nav/>
            <div className="w-11/12 mt-16 md:mt-28 md:w-4/6 mx-auto">
                <h1 className="text-2xl md:text-5xl hd text-center">Software Services</h1>
                <p className="text-center my-4 md:my-6 w-full md:w-10/12 mx-auto">
                Transform your business with tailor-made software solutions that meet your specific requirements. Our skilled software development team brings your ideas to life, crafting custom                </p>
            </div>

            <img className="w-screen mt-8 md:mt-12 object-cover h-[40vh] md:h-[60vh]" src={sw2} alt='' />
            
            <div className="w-full md:w-4/6 my-5 p-6 md:p-8 flex md:rounded-xl md:flex-row-reverse flex-wrap md:flex-nowrap justify-between bg-tqdarkblue/5 md:min-h-[30vh] md:mx-auto">
                <div className="">
                    <h3 className="text-3xl font-semibold">Software Services</h3>
                    <p className="mx-auto md:mx-0 font-light mt-4 opacity-80">TeqInfinity offers a comprehensive range of services to cater to diverse business needs. The software development services provided by TeqInfinity include:</p>
                    <ul className="mt-8 ml-3">
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold' >Custom Software Development
                            {/* <p>TeqInfinity develops tailor-made software solutions to meet specific business requirements. They work closely with clients to understand their needs, design the software architecture, and deliver scalable and efficient software applications.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>Web Application Development
                            {/* <p>TeqInfinity specializes in building dynamic and interactive web applications using the latest technologies and frameworks. They create user-friendly interfaces, implement robust back-end functionalities, and ensure responsive designs for optimal user experience.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>Mobile App Development
                            {/* <p>TeqInfinity develops mobile applications for various platforms, including iOS and Android. They employ native or cross-platform frameworks to create intuitive and feature-rich mobile apps that align with clients' branding and business objectives</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>Enterprise Software Solutions
                            {/* <p>TeqInfinity provides enterprise software development services to help organizations streamline their internal processes and enhance operational efficiency. They build scalable and secure enterprise applications, including CRM systems, ERP solutions, supply chain management tools, and more.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>E-commerce Solutions
                            {/* <p>TeqInfinity develops e-commerce platforms and online marketplaces to enable businesses to sell products and services online. They create user-friendly interfaces, implement secure payment gateways, and integrate inventory management and order processing systems.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>Software Integration and API Development
                            {/* <p>TeqInfinity develops tailor-made software solutions to meet specific business requirements. They work closely with clients to understand their needs, design the software architecture, and deliver scalable and efficient software applications.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>Cloud Application Development
                            {/* <p>TeqInfinity leverages cloud technologies to develop scalable and cost-effective cloud applications. They design and develop cloud-native applications, migrate on-premises applications to the cloud, and integrate cloud services for enhanced functionality.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>Software Testing and Quality Assurance
                            {/* <p>TeqInfinity provides comprehensive software testing services to ensure the reliability, functionality, and performance of software applications. They perform various testing activities, including functional testing, regression testing, performance testing, and user acceptance testing.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>Maintenance and Support
                            {/* <p>TeqInfinity offers ongoing software maintenance and support services to ensure the smooth operation of software applications. They provide bug fixes, updates, and enhancements, as well as offer technical support and troubleshooting.</p> */}
                        </li>
                        <li className='list-item text-xl my-3 mt-6 text-tqdarkblue/70 font-semibold'>UI/UX Design
                            {/* <p>TeqInfinity focuses on creating intuitive and visually appealing user interfaces (UI) and delivering seamless user experiences (UX). They employ best practices in UI/UX design to ensure user satisfaction and engagement with software applications.</p> */}
                        </li>
                    </ul>
                </div>
            </div>

            <Footer/>
        </motion.main>
    )
}

export default DatacenterServices;